import cx from 'classnames'
import { useContext, useRef, useState } from 'react'
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts'

import { type SanityProductModule } from '@data/sanity/queries/types/modules'
import { type SourceOptionsProps } from '@lib/image'
import { ProductContext } from '@lib/product-context'

import Photo from '@components/photo'

type ProductModuleProps = Pick<
  SanityProductModule,
  'url' | 'title' | 'price' | 'image'
> & {
  imageSize?: number
  isInSlider?: boolean
  className?: string
}

interface Size {
  width: number
  height: number
}

const getImageOptions = (
  isInSlider: boolean,
  linkWidth?: number,
  imageSize?: number,
): Pick<SourceOptionsProps, 'width' | 'height'> | undefined => {
  const sizeMultiplier = 4

  if (!isInSlider && typeof imageSize !== 'undefined') {
    return {
      width: imageSize * sizeMultiplier,
      height: imageSize * sizeMultiplier,
    }
  }

  if (isInSlider && typeof linkWidth !== 'undefined') {
    return {
      width: linkWidth * sizeMultiplier,
      height: linkWidth * sizeMultiplier,
    }
  }
}

const ProductModule = ({
  url,
  title,
  price,
  image,
  imageSize,
  isInSlider,
  className,
}: ProductModuleProps) => {
  const { currency } = useContext(ProductContext)

  const linkRef = useRef<HTMLAnchorElement>(null)
  const [linkSize, setLinkSize] = useState<Partial<Size>>({
    width: imageSize,
    height: imageSize,
  })
  const debouncedSetSize = useDebounceCallback(setLinkSize, 200)
  useResizeObserver({
    ref: linkRef,
    onResize: debouncedSetSize,
  })

  const imageOptions = getImageOptions(!!isInSlider, linkSize.width, imageSize)
  const priceString = price ? [price, currency].filter(Boolean).join(' ') : null

  return (
    <a
      ref={linkRef}
      href={url}
      target="_blank"
      className={cx('flex flex-col gap-2 rounded-md', className)}
    >
      {!!image && (
        <Photo
          image={image}
          width={!!isInSlider ? linkSize.width : imageSize}
          height={!!isInSlider ? linkSize.width : imageSize}
          imageOptions={imageOptions}
          className="flex-none"
          imageClassName="rounded-md"
        />
      )}
      <div className="w-full gap-1">
        <h3 className="line-clamp-2 text-ellipsis" title={title}>
          {title}
        </h3>
        {!!priceString && <p className="opacity-60">{priceString}</p>}
      </div>
    </a>
  )
}

export default ProductModule
